.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw; /* Responsive font size */
  color: #31af5c;
  position: relative; /* Pour des ajustements ultérieurs */
}

h1 {
  display: inline-block;
  position: relative;
  margin: 0; /* Assurez-vous qu'il n'y a pas de marges par défaut */
}

h1 span {
  display: inline-block;
  opacity: 0;
  animation: typeLetter 0.5s forwards;
}

h1 span:nth-child(1) { animation-delay: 0.5s; }
h1 span:nth-child(2) { animation-delay: 1s; }
h1 span:nth-child(3) { animation-delay: 1.5s; }
h1 span:nth-child(4) { animation-delay: 2s; }
h1 span:nth-child(5) { animation-delay: 2.5s; }

@keyframes typeLetter {
  to {
    opacity: 1;
  }
}

.cursor {
  opacity: 0;
  animation: blink 1s step-end infinite 3s; /* Start blinking after 3 seconds */
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
